import React, { useState, useEffect } from 'react';//
import Web3 from "web3";
import { Button } from './Button';
import './Mint.css';
import {ADDRESS, ABI} from "../config.js";
import whiteListMintPass from "../new_data.json";
import UpdaterTag from './Updater';







function Mint() {

    // FOR WALLET
    const [signedIn, setSignedIn] = useState(false)

    const [walletAddress, setWalletAddress] = useState(null) //

    // FOR MINTING
    const [how_many_nuggets, set_how_many_nuggets] = useState(1)
    const [freindAddy, setFreindAddy] = useState('')
    const [how_many_nuggets_allow, set_how_many_nuggets_allow] = useState(1)

    const [nuggetContract, setNuggetContract] = useState(null)//

    // INFO FROM SMART Contract

    const [totalSupply, setTotalSupply] = useState(2)

    const [saleStarted, setSaleStarted] = useState(null)//

    const [getPrice, setNuggetPrice] = useState(0)//

    const [notInvited, setNotInvited] = useState(false)
    const [realnow, setRealNow] = useState(0)
    const [now, setNow] = useState(0);
    const [currentTime, setCurrentTime] = useState(0)
    const [totalSold, setTotalSold] = useState(0)
    const [gasNow, setGasNow] = useState(0)


    const MINUTE_MS = 2000;

    async function countitnow(){
        const newNow = Date.now()/1000;
       setNow(newNow);
       // console.log(now)
    }
    useEffect(() => {
      const interval = setInterval(() => {
        countitnow()
        setCurrentTime(Date.now()/1000)
        
        // console.log(currentTime)

        // console.log(newNow);
        // console.log(now);

      }, MINUTE_MS);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     if(signedIn==true){
    //     getTheCount()
    //
    //     } else{
    //     console.log('nuthin');
    //     }
    //   }, MINUTE_MS);
    //
    //   return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // }, [])

//    useEffect( async() => {
//
//        signIn()
//
//    }, [])

    function signIn() {
        if (typeof window.web3 !== 'undefined') {
            // Use existing gateway
            window.web3 = new Web3(window.ethereum);
        } else {
            alert("No Ethereum interface injected into browser. Read-only access");
        }

        window.ethereum.enable()
        .then(function (accounts) {
            window.web3.eth.net.getNetworkType()
            // checks if connected network is mainnet (change this to rinkeby if you wanna test on testnet)
            .then((network) => {console.log(network);if(network != "main"){alert("You are on " + network+ " network. Change network to mainnet or you won't be able to do anything here")} });
            let wallet = accounts[0]
            setWalletAddress(wallet)
            var searchAddy2 = wallet.toLowerCase();
            try{setWhiteStatus(whiteListMintPass[searchAddy2]["address"])
            }catch (err) {
                setNotInvited(true)
            }
            setSignedIn(true)
            callContractData(wallet)
        })
        .catch(function (error) {
            // Handle error. Likely the user rejected the login
            console.error(error)
        })
    }
    //
    function signOut() {
        setSignedIn(false)
    }
    async function getTheCount() {
        
        console.log('e')
    }

    async function callContractData(wallet) {
        // let balance = await web3.eth.getBalance(wallet);
        // setWalletBalance(balance)
        const nuggetContract = new window.web3.eth.Contract(ABI, ADDRESS)
        setNuggetContract(nuggetContract)

        



        

        




    }

    async function mintMintPass(how_many_nuggets) {
        if (nuggetContract) {

            try{
            const price = (Number(150000000000000000) * how_many_nuggets)
            const gasPrice = (price*4)
            console.log(price)

                const gasAmount = await nuggetContract.methods.mintETHWalkerMintPass(how_many_nuggets).estimateGas({from: walletAddress, value: price})


            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            nuggetContract.methods
                .mintETHWalkerMintPass(how_many_nuggets)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })

            }catch (error) {
                console.log('User may have insufficient funds for mint');
                console.log(error);
                alert("User may have insufficient funds for mint. Or Mint may be closed(closes July4th 2:00pm PDT)")
            }

        } else {
            console.log("Wallet not connected")
        }
    };
    async function freeMintPass(how_many_nuggets, freindAddy) {
        if (nuggetContract) {

            try{
            const price = 0
            const gasPrice = (price*4)
            console.log(price)

                const gasAmount = await nuggetContract.methods.friendsAndFreeETHWalkersMiamiMint(how_many_nuggets, freindAddy).estimateGas({from: walletAddress, value: price})


            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            nuggetContract.methods
                .friendsAndFreeETHWalkersMiamiMint(how_many_nuggets, freindAddy)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })

            }catch (error) {
                console.log('User may have insufficient funds for mint');
                console.log(error);
                alert("User may have insufficient funds for mint. Or Mint may be closed(closes July4th 2:00pm PDT)")
            }

        } else {
            console.log("Wallet not connected")
        }
    };
    const [whiteStatus, setWhiteStatus] = useState('')

    async function allowMintMintPass(how_many_nuggets_allow) {
        if (nuggetContract) {
            try{
            var searchAddy2 = walletAddress.toLowerCase()

            var whiteListMintPassUsable = JSON.stringify(whiteListMintPass)


            const newboy1 = whiteListMintPass[searchAddy2]["address"];
            const newboy2 = whiteListMintPass[searchAddy2]["index"];
            const newboy3 = whiteListMintPass[searchAddy2]["sig"];







            const price = (Number(150000000000000000) * how_many_nuggets_allow)
            const mr_index = whiteListMintPass[searchAddy2]["index"];
            const mr_signature = whiteListMintPass[searchAddy2]["sig"];
            const gasPrice = (price*4)
            console.log(price)


                const gasAmount = await nuggetContract.methods.allowlistETHWalkerMintPass(how_many_nuggets_allow, mr_index, mr_signature).estimateGas({from: walletAddress, value: price})

            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            nuggetContract.methods
                .allowlistETHWalkerMintPass(how_many_nuggets_allow, mr_index, mr_signature)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })
            }catch (error) {
                console.log('User may have insufficient funds for mint');
                console.log(error);
                alert("User may have insufficient funds for mint. Or Mint may be closed(closes July4th 2:00pm PDT)")
            }

        } else {
            console.log("Wallet not connected")
        }
    };






const handleClick = () => {
    //console.log('Hello there!');
    freeMintPass(how_many_nuggets, freindAddy)
};
const handleClickAllow = () => {
    //console.log('Hello there!');
    allowMintMintPass(how_many_nuggets_allow)
};
return (
    <div className="daddy">
        <div className='new-body'>
            <div className='hero-container-Private'>





        </div>






        </div>
        
        <div className="FAQ-container">

        {Date.now()/1000<=1656968401 ?
        <>
        <div>
        <h1>The ETHWalkers MintPass Mint is Closed</h1>
        </div>
        <h1>Verified on Etherscan:</h1>
        <p><a href="https://etherscan.io/address/0x7eca22913103e4a9d92b1ffd892b86d1906093e5" link="white" style={{color: 'white'}} vlink="red">0x7eCa22913103e4a9D92b1FfD892b86d1906093E5</a></p>
        </>
        : <div className='do-not-display'>
                <h3></h3>
            </div>
        }
        {Date.now()/1000>=1656968401 ?
        <>
    <h1>Friends Mint Promo (limit of 4 per )</h1>
        {signedIn?
            <>
            

            
            <span style={{color: 'white', textAlign: 'center'}}>Input your friend's address below and mint up to 4 ETHWalkers for free</span>

            <div id="mint" className="flex">
            <span className="flex">Friend Address:</span>
            <input
                style={{color: 'black'}}
                type="string"
                value={freindAddy}
                onChange={ e => setFreindAddy(e.target.value) }
                name=""
            />
            </div>

        <div id="mint" className="flex">


        <span className="flex">Mint</span>

        <input
            style={{color: 'black'}}
            type="number"
            min="1"
            max="4"
            value={how_many_nuggets}
            onChange={ e => set_how_many_nuggets(e.target.value) }
            name=""
        />
        <span className="flex">Walkers</span>

        </div>
        
        </>
        :<div className='do-not-display'></div>}
            <div className='hero-btns'>
            {signedIn?
            <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
                onClick={handleClick}
            >
            MINT {how_many_nuggets} Mint Passes (You Pay GAS)
            </Button>
            : <Button
                onClick={signIn}
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
            >Click to Connect Wallet with Metamask
            </Button>
            }
             <h1 style={{ textAlign: 'center'}}>Verified on Etherscan:</h1>
            <p><a href="https://etherscan.io/address/0xd1d5a25df16026309686f1a345ee38de3ecc200f" link="white" style={{color: 'white'}} vlink="red">0xd1D5a25dF16026309686F1a345eE38de3eCc200F</a></p>
            </div>
            
            
            </>
            : <div className='do-not-display'>
                    <h3></h3>
                </div>
            }
            </div>
            
    </div>



);
}
export default Mint;
